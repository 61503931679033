import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/images/footer-logo.png";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  const [classActive , setClassActive ]= useState(false)
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const buttonRef = useRef(null);

  useEffect(() => {
    const scrollFunction = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {

        
        buttonRef.current.style.height = "45px";
        buttonRef.current.style.width = "45px";
      } else {
        buttonRef.current.style.height = "0px";
        buttonRef.current.style.width = "0px";
        
      }
    };

    const backToTop = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };

    window.onscroll = scrollFunction;
    buttonRef.current.addEventListener("click", backToTop);

    // Cleanup on unmount
    return () => {
      window.onscroll = null;
      buttonRef.current.removeEventListener("click", backToTop);
    };
  },[]);

  return (
    <footer>
      {/*-------------footer-----------------start-----------*/}
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3 col-lg-3 footer-info">
              <Link
                to={"/"}
                onClick={handleLinkClick}
                className="logo d-flex align-items-center"
              >
                <img className="img-fluid" src={footerLogo} />
              </Link>
              <p className="pt-1">
                "SatelliteKey IT Solutions Pvt. Ltd" is a dynamic and innovative
                company dedicated to providing customized software to meet the
                unique needs of its customers with excellence and customer
                satisfaction in mind.
              </p>
              <div className="top-right footer-right">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/satellitekeyit"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/SatelliteKeyIT?t=xb05qvykeRZZG2THNPCqYg&s=09"
                      target="_blank"
                    >
                      <i className="fa-brands fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/satellitekeyit/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/satellitekey?igsh=aG53bDg5MjJ4ZDA1"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3 footer-links">
              <h4 className="footer-tital">Useful Links</h4>
              <ul>
                <li>
                  <Link onClick={handleLinkClick} to={"/about"}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to={"/services"}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to={"/portfolio"}>
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to={"client"}>
                    Client Review
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to={"/careers"}>
                    Careers
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to={"/contact"}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3 footer-links">
              <h4 className="footer-tital">Support</h4>
              <ul>
                <li>
                  <Link onClick={handleLinkClick} to={"/faq"}>
                    Frequently Asked Questions
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to={"/term"}>
                    Terms and Conditions{" "}
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to={"/privacy"}>
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3 footer-links get-touch">
              <h4 className="footer-tital">Get In Touch</h4>
              <ul>
                <li>
                  <a
                    href="mailto:info@satellitekeyit.com"
                    target="_blank"
                    // style={{ color: "white", textDecoration: "none" }}
                  >
                    <i className="fa-regular fa-envelope px-2"  />
                         info@satellitekeyit.com
                  </a>
                </li>
                <li>
                  <i className="fa-solid fa-phone px-1" /> +91 95298 99325
                </li>
                <li style={{ display: "flex" }}>
                  <i className="fa-solid fa-location-dot px-2 py-1" />
                  <div>
                    Yogi Tower, Mahalaxmi Nagar Road, D-Block, Malviya Nagar,
                    Jaipur - 302017, Rajasthan
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-main">
        © Copyright 2024 Satellitekeyit.com. All Rights Reserved.
      </div>
      {/* footer-----end---*/}

      {/* <a
        href="https://api.whatsapp.com/send?phone=9529899325"
        className="float"
        target="_blank"
      >
        <i className="fa-brands fa-whatsapp my-float"></i>
        <FaWhatsapp />
      </a> */}

      {/* // <button
      //   type="button"
      //   className="btn btn-danger btn-floating btn-lg"
      //   ref={buttonRef}
      //   id="btn-back-to-top"
      // >
      //   <i className="fas fa-arrow-up"></i>
      // </button> */}

      <div className="FooterIcons">
        <a
          className="float"
          href="https://api.whatsapp.com/send?phone=9529899325"
          target="_blank"
        >
          <FaWhatsapp />
        </a>

        <button
          type="button"
          className={`btn btn-danger btn-floating btn-lg ${classActive? "activeFooter": ""} `}
          ref={buttonRef}
          id="btn-back-to-top"
        >
          <i className="fas fa-arrow-up"></i>
        </button>
      </div>
    </footer>
  );
};

export default Footer;
